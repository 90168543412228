// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/githera.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.App {
  background-color: #FFF ;
  font-family: 'Githera Regular', sans-serif;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
}


@font-face {
  font-family: 'Githera Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-weight: normal;
  font-style: normal;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,uBAAuB;EACvB,0CAA0C;EAC1C,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;AACpB;;;AAGA;EACE,8BAA8B;EAC9B,+DAAkD;EAClD,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["\n.App {\n  background-color: #FFF ;\n  font-family: 'Githera Regular', sans-serif;\n  max-width: 1920px;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n\n@font-face {\n  font-family: 'Githera Regular';\n  src: url('./fonts/githera.otf') format('truetype');\n  font-weight: normal;\n  font-style: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
