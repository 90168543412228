// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/githera.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/LibreFranklin-ExtraLight.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.App {
  background-color: #F7F4EF;
  font-family: 'Githera Regular', sans-serif;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}


@font-face {
  font-family: 'Githera Regular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'Libre Franklin Light';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
  font-weight: 200;
  font-style: normal;
}



@keyframes slide-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.animate-slide {
  animation: slide-right 200s cubic-bezier(0.25, 0.8, 0.25, 1) infinite;
}








`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,yBAAyB;EACzB,0CAA0C;EAC1C,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;AAClB;;;AAGA;EACE,8BAA8B;EAC9B,+DAAkD;EAClD,kBAAkB;AACpB;;AAEA;EACE,mCAAmC;EACnC,+DAAmE;EACnE,gBAAgB;EAChB,kBAAkB;AACpB;;;;AAIA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;AACF;;AAEA;EACE,qEAAqE;AACvE","sourcesContent":["\n.App {\n  background-color: #F7F4EF;\n  font-family: 'Githera Regular', sans-serif;\n  margin-left: auto;\n  margin-right: auto;\n  overflow: hidden;\n}\n\n\n@font-face {\n  font-family: 'Githera Regular';\n  src: url('./fonts/githera.otf') format('truetype');\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Libre Franklin Light';\n  src: url('./fonts/LibreFranklin-ExtraLight.ttf') format('truetype');\n  font-weight: 200;\n  font-style: normal;\n}\n\n\n\n@keyframes slide-right {\n  0% {\n    transform: translateX(0);\n  }\n  100% {\n    transform: translateX(-100%);\n  }\n}\n\n.animate-slide {\n  animation: slide-right 200s cubic-bezier(0.25, 0.8, 0.25, 1) infinite;\n}\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
