import React from 'react';

const Footer = () => {
  return (
    <section className="image-container bg-customBg" style={{height:"310px"}}>
      <p style={{fontSize:"50px", display:"flex" , justifyContent:"center", alignItems:"center"}}>Footer here</p>
    </section>
  );
};

export default Footer;