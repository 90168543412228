// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes float {
  0% {
    transform: translateY(-10px);  /* Start slightly above the center */
  }
  50% {
    transform: translateY(10px);   /* Move down a little */
  }
  100% {
    transform: translateY(-10px);  /* Move back up to the starting position */
  }
}

.animation-float {
  animation: float 3s ease-in-out infinite; /* 3s duration, ease-in-out timing, infinite loop */
}
`, "",{"version":3,"sources":["webpack://./src/components/float.css"],"names":[],"mappings":"AAAA;EACE;IACE,4BAA4B,GAAG,oCAAoC;EACrE;EACA;IACE,2BAA2B,IAAI,uBAAuB;EACxD;EACA;IACE,4BAA4B,GAAG,0CAA0C;EAC3E;AACF;;AAEA;EACE,wCAAwC,EAAE,mDAAmD;AAC/F","sourcesContent":["@keyframes float {\n  0% {\n    transform: translateY(-10px);  /* Start slightly above the center */\n  }\n  50% {\n    transform: translateY(10px);   /* Move down a little */\n  }\n  100% {\n    transform: translateY(-10px);  /* Move back up to the starting position */\n  }\n}\n\n.animation-float {\n  animation: float 3s ease-in-out infinite; /* 3s duration, ease-in-out timing, infinite loop */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
